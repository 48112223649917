:root {
    --primary-color: #61dafb;
    --header-color: #20232a;
    --text-color: #1a1a1a;
    --text-color-alter: #fff;
    --text-color-fade: #6d6d6d;

    --shade-color: #f7f7f7;

    --size-xl: 2rem;
    --size-l: 1.5rem;
    --size-xm: 1.2rem;
    --size-m: 1rem;
    --size-s: .9rem;
    --size-xs: .8rem;

    --gap: var(--size-m);
}

:root [branch="css"] {
    --primary-color: #0068BA;
}

:root [branch="javascript"] {
    --primary-color: #EFD81D;
}