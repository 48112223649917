.Responsive .web {
    display: block;
}

.Responsive .mobile {
    display: none;
}

@media screen and (max-width: 550px) {
.Responsive .web {
    display: none;
}

.Responsive .mobile {
    display: block;
}
}