.Grid {
    display: grid;
    height: 100%;
}

.Grid.header {
    grid-template-rows: 60px 1fr;
}

.Grid.header > :nth-child(1) {
    background-color: var(--header-color);
    color: var(--text-color-alter);
}

.Grid.side {
    grid-template-columns: 1fr  minmax(200px, 30%);
}

.Grid.side > * {
    padding: var(--size-xl) 0;
    height: calc(100vh - 6vh - (var(--size-xl) * 2) - 4px);
    overflow: auto;
}

.Grid.side > * > * {
    margin-top: 5rem;
    margin-bottom: 5rem;
}

.Grid.side > :nth-child(1) {
    padding-right: var(--size-xl);
}

.Grid.side > :nth-child(2) {
    background-color: var(--shade-color);
    color: var(--text-color);
    padding-right: 200%;
    padding-left: var(--size-xl);
    width: 100%;
    left: 50%;
    transition: .2s left;
}

.Grid.side > :nth-child(2) .row {
    border-bottom: 1px solid #e1e1e1;
}

.Grid.side > :nth-child(2) a {
    height: auto;
    align-items: flex-start;
    justify-content: flex-start;
    border-left: 3px solid transparent;
    padding-bottom: 5px;
}

.Grid.side > :nth-child(2) .Icon.ellipsis-v,
.Grid.side > :nth-child(2) .Icon.times {
    display: none;
}

.Grid.side > :nth-child(2) .row a.active {
    font-weight: 700;
    color: inherit;
    border-bottom: 3px solid transparent;
    border-left: 3px solid var(--primary-color);
}

.Grid.side > :nth-child(2) .sub {
    padding-left: 1.5rem;
}

.Grid.side > :nth-child(2) .sub a {
    font-size: var(--size-xs);
}

@media screen and (max-width: 550px) {
    .Grid.side {
        grid-template-columns: 1fr;   
    }

    .Grid.side > :nth-child(2) {
        box-shadow: -2px 2px 14px #333;
        position: absolute;
        padding-right: 0;
        width: 5%;
        left: 95%;
        overflow: hidden;
    }

    .Grid.side > :nth-child(2) .Icon.times {
        display: block;
    }

    .Grid.side > .closed:nth-child(2) .Icon.ellipsis-v {
        display: block;
        position: absolute;
        right: 40px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 22;
    }

    .Grid.side > .open:nth-child(2) {
        width: 80%;
        left: 5%;
        padding-right: 10%;
        overflow: auto;
    }
}