/* code box */
.wmde-markdown pre code,
.wmde-markdown pre {
    background-color: #1E1E1E;
}

/* code box color */
.wmde-markdown-color code[class*='language-'] {
    color: #9CCEC9;    
}

.wmde-markdown-color code[class*='language-linux'], 
.wmde-markdown-color code[class*='language-npm'] {
    color: #f1f1f1;
}

.wmde-markdown-color code[class*='language-link'] {
    color: #9CDCFE;
}

.w-md-editor-text * {
    color: var(--text-color) !important;
}

/* html tag */
.wmde-markdown-color .token.tag {
    color: #378DD6;   
}

/* html tag attr */
.wmde-markdown-color code[class*='language-'] .token.attr-name {
    color: #9CDCF1;   
}

/* html tag attr value */
.wmde-markdown-color code[class*='language-'] .token.attr-value {
    color: #CE9178;
}

/* css prefix */
.wmde-markdown-color code[class*='language-'] .token.selector {
    color: #D7BA7D;
}

/* css property */
.wmde-markdown-color .token.property {
    color: #9CDCFE;
}

/* css value */
.wmde-markdown-color code[class*='language-'] .token.string {
    color: #CE9178;
}

/* comment */
.wmde-markdown-color code[class*='language-'] .token.comment {
    color: #6A993E;
}


.wmde-markdown-color code[class*='language-'] .token.function {
    color: #DCDCAA;
}

.wmde-markdown-color code[class*='language-'] .token.keyword {
    color: #4A9CD6;
}

.wmde-markdown-color code[class*='language-'] .token.punctuation,
.wmde-markdown-color code[class*='language-'] .token.operator, 
.wmde-markdown-color code[class*='language-'] .token.variable {
    color: #D4D4B1;
    background-color: transparent;
}

.wmde-markdown-color code[class*='language-'] .token.plain-text {
    color: #f1f1f1;
}

.wmde-markdown-color code[class*='language-'] .token.class-name {
    color: #4EC9A4;
}

.wmde-markdown a {
    display: initial;
    padding: initial;
    color: var(--primary-color);
}
