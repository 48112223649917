.Line {
    display: flex; 
    gap:var(--gap);  
    height: 100%;
    align-items: center;
}

.Line.Between {
    justify-content: space-between;
}

.Line.Rows {
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    height: auto;
}

.Line.Rows > .Line {
    height: auto;
}