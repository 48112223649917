.Btn {
    background-color: transparent;
    color: var(--primary-color);
    padding: var(--size-xs) var(--size-xs);
    border:none;
    font-size: var(--size-xs);
}

.Btn::selection { background: transparent; }
.Btn::-moz-selection { background: transparent; }

.Btn:hover {
    cursor: pointer;
    text-decoration: underline;
}

.Btn:active {
    opacity: 0.8;
}

.Btn .Line {
    gap: var(--size-xs);
}

.Btn .Icon {
    color: var(--header-color);
}