html, body, #root, .App {
    height: 100%;
}

body {
    margin: 0;
    overflow-x: hidden;
}

body, input, button {
    font-size: 16px;
    font-family: 'Inter', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
}

h3, h6 {
    font-weight: 300;
}

a {
    color: inherit;
    text-decoration: none;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border-bottom: 3px solid transparent;
    padding: 0 var(--size-m);
}

a.active {
    color: var(--primary-color);
    border-bottom: 3px solid var(--primary-color);
}

.wmde-markdown-color code[class*='language-'] {
    overflow: auto;
    width: max-content;
}


@media screen and (max-width: 550px) {
    html, body, #root, .App {
        position: fixed;
        z-index: 10;
        inset: 0;
    }
}